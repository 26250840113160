import $ from 'jquery'
import _ from 'underscore'
import { ajax } from 'util/ajax'
import { ifvisible } from '@rosskevin/ifvisible' // eslint-disable-line import/no-unresolved

import {
    isRenderableVideoType,
    postRenderImages,
} from 'util/twistle'

import { renderTemplate } from 'util/template_renderer'
import { isExtendedWorkflowContent } from 'app/mixins/mixin_utils'
import DialogView from 'app/views/dialog_view'

import 'mediaelement'
window.Hls = require("hls.js")

import ConversationDetailNotesView from 'app/views/conversation_detail_notes_view'

const AttachmentDisplayDialogView = DialogView.extend({
    className: "attachment_dialog",
    initialize: function(options) {
        _.bindAll.apply(_, [this].concat(_.functions(this)));

        this.options = _.extend({}, this.options, options, {
            title: "Attachment",
            dialogClass: "attachment_dialog_panel dialog_panel",
            overlayClass: "attachment_dialog_overlay",
            width: "350",
            height: "auto"
        });
        const self = this;
        DialogView.prototype.initialize.call(this, this.options);
        self.render();

    },

    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {
            "click .download_file": "downloadFile"
        });
    },

    getRoute:function(){
        return "attachment/" + this.options.attachment.seqnum;
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        if(!self.rendered)
        {
            self.setTitle(self.options.attachment.description || "Attachment:");
            if(isRenderableVideoType(self.options.attachment.type))
            {
                let playerWidth = self.options.playerWidth ||  400;
                let playerHeight = self.options.playerHeight || 400;

                var renderVideo = function()
                {
                    if (self.options.attachment.has_hls_playlist)
                    {
                        self.options.attachment.video_content_type = "application/x-mpegURL";
                        self.options.attachment.endpoint = self.options.attachment.endpoint + "&size=playlist"
                    }
                    else
                    {
                        self.options.attachment.video_content_type = self.options.attachment.type;
                    }

                    self.getEl().html(renderTemplate('attachment_dialog_video_template', self.options.attachment));
                    let $video = self.getEl().find("video");
                    $video.prop("width", Math.min($(window).width()-20, playerWidth));
                    $video.prop("height", playerHeight);

                    if (self.options.attachment.has_hls_playlist)
                    {
                        var mediaElementOpts = {
                            iconSprite: '/converse/images/mejs-controls.svg',
                            success: function (mediaElement, originalNode) {
                                var duration;

                                //after metadata is loaded we can access the duration
                                mediaElement.addEventListener('loadedmetadata', function (e) {
                                    duration = mediaElement.duration;
                                });

                                // listen to video time watch events
                                mediaElement.addEventListener('timeupdate', function (e) {
                                    var durationSec = parseInt(mediaElement.currentTime, 10);
                                    var percentage = (mediaElement.currentTime / duration).toFixed(2);
                                    const videoUnfinished = percentage > 0.0 && percentage <= 1.0;

                                    // send to server (if page is visible)
                                    if (videoUnfinished && ifvisible.now()) {
                                        self._sendVideoUsage(durationSec, percentage);
                                    }
                                }, false);

                                if (!self.options.openInPausedState) {
                                    mediaElement.play();
                                }
                            }
                        };
                        if (App.config.get("isAndroid")) {
                            // on Android, favor the hls.js renderer
                            mediaElementOpts.renderers = ["native_hls", "html5"];
                        }
                        $video.mediaelementplayer(mediaElementOpts);
                    }
                    else
                    {
                        $video.prop("controls", true);
                        if (!self.options.openInPausedState) {
                            $video.prop("autoplay", true);
                            $video[0].play();
                        }
                    }
                    self.$(".attachment_dialog").spin({stop:true});
                    if (!self.options.fullyPreventDialog) {
                        if (!self.isOnStack) {
                            self.getEl().dialog("option", "height", 620);
                        }

                        self.reposition(500);
                    }
                };

                self.$(".attachment_dialog").css("min-height",180).spin();
                if (!self.options.attachment.endpoint || App.config.get("noCookies")) {
                    ajax.request("/attachment/Info", {
                        seqnum: self.options.attachment.seqnum,
                        retrieve_url: true,
                        generate_access_token: App.config.get("noCookies")
                    }, [], function (jsonResp) {
                        self.options.attachment = jsonResp;
                        if (App.config.get("noCookies")) {
                            self.options.attachment.endpoint = `${self.options.attachment.endpoint}&access_token=${self.options.attachment.access_token}`
                        }
                        renderVideo();
                    });
                } else {
                    renderVideo();
                }
            }
            else if (isExtendedWorkflowContent(self.options.attachment)) {
                // insert a notes view for this conversation/note associated with the "extended workflow content" attachment
                let extendedProps = JSON.parse(self.options.attachment?.extended_attributes || "{}");
                let convModel = new App.Conversation({id: extendedProps?.convseq});
                self.getEl().spin();
                convModel.fetch({
                    success:function(){
                        self.getEl().spin({stop:true});
                        convModel.attributes.notes = convModel.attributes.notes.filter(n => n.id === extendedProps.note_id);
                        let notesView = self.addChildView(new ConversationDetailNotesView({
                            model:convModel
                        }));
                        notesView.$el.appendTo(self.getEl());
                    }
                });
            }
            else {
                if (!self.options.attachment.endpoint) {
                    ajax.request("/attachment/Info", {
                        seqnum: self.options.attachment.seqnum,
                        retrieve_url: true
                    }, [], function (jsonResp) {
                        self.options.attachment = jsonResp;
                    });
                }
                var attachData = _.extend(_.clone(self.options.attachment),
                    {
                        url: "/attachment/View?seqnum=" + self.options.attachment.seqnum,
                        thumb_size: "full",
                        thumb_url: self.options.attachment.hasThumbnail ?
                            ("/attachment/View?size=std&seqnum=" + self.options.attachment.seqnum) :
                            "/converse/images/responsive/Unknown_Attachment@2x.png",
                        loadFullSize: self.options.loadFullSize
                    });
                if (attachData.type === "image" || attachData.type === "video") {
                    attachData.caption = "";
                } else {
                    attachData.caption = "Download " + self.options.attachment.description;
                    attachData.thumb_class = "unknown_attachment";
                }
                if (self.options.openInNewWindow) {
                    attachData.target = "target = \"_blank\"";
                }
                self.getEl().html(renderTemplate('attachment_dialog_template', attachData));
                self.$(".items_loading_indicator").spin();
                self.$("img").imagesLoaded(self.resize);
                postRenderImages(self.getEl(), self.resize);
            }
            self.rendered = true;
        }

        return self;
    },

    // silently sends video watching telemetry to the server
    _sendVideoUsage: _.throttle(function (timeWatched, percentage) {
        var self = this;
        ajax.request("/util/TrackVideoUsage", {
            seqnum: self.options.attachment.seqnum,
            duration_watched: timeWatched,
            percent_watched: percentage
        }, [], function () {
        }, false, function () {
        });
    }, 7500),

    resize: function(evt, noPosition) {
        var self = this,
            currentHeight = self.$(".attach_thumb img").height(),
            currentWidth = self.$(".attach_thumb img").width(),
            windowHeight = $(window).height(),
            windowWidth = $(window).width(),
            adjustedHeight = Math.min(currentHeight + 125, (windowHeight - 60)) + 60, // account for the title bar of the dialog
            adjustedWidth = Math.min(currentWidth, (windowWidth - 90)) + 20;

        self.$(".items_loading_indicator").hide();
        if (!self.options.fullyPreventDialog) {
            if (!self.isOnStack) {
                self.getEl().dialog("option", "height", adjustedHeight);
                self.getEl().dialog("option", "width", adjustedWidth);
                if (!noPosition) {
                    self.reposition();
                }
            }
        }
    },
    resizeWithoutPosition: function(evt) {
        var self = this;
        self.resize(evt, true);
    },
    downloadFile: function(e) {
        e.preventDefault();
        App.trigger("app:show_attachment", this.options.attachment.seqnum, this.options.attachment, false, false, true); // forces a download
    }
});

export default AttachmentDisplayDialogView;